<template>
  <page-title class="q-mb-lg" :title="$t('account.company_data.title')" />

  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <div
      class="q-pa-lg bg-grey-3 q-mb-md text-weight-medium text-justify"
      v-html="$t('account.company_data.disclaimer')"
    />

    <Form
      class="q-gutter-md q-mt-lg"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="row form-inputs-content justify-between">
        <Field
          v-for="(structure, key) in structureForm"
          :key="key"
          :name="structure.name"
          v-slot="{ errorMessage, value, field }"
        >
          <q-input
            filled
            clearable
            bottom-slots
            v-bind="field"
            class="q-mb-sm"
            style="width: 100%"
            :type="structure.type"
            :mask="structure.mask"
            :model-value="value"
            :label="structure.label"
            :error="!!errorMessage"
            :error-message="errorMessage"
            :dense="$q.platform.is.mobile"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :stack-label="structure['stack-label']"
          >
            <template v-slot:before>
              <q-icon :name="structure.icon" />
            </template>
          </q-input>
        </Field>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          outline
          color="grey"
          type="submit"
          :loading="in_request"
          :disable="in_request"
          :label="$t('global.save')"
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
        />
      </q-card-section>
    </Form>
  </q-intersection>
</template>

<script>
import {
  build,
  labels,
  getStructure,
  getMappedFields,
  buildInitialValues,
  getFormValuesChanged,
} from "@/shared/form-presets";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { useGetters, useActions } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { Field, Form } from "vee-validate";
import { ref } from "vue";

export default {
  name: "CompanyData",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const in_request = ref(false),
      { partner } = useGetters({
        partner: "account/getPartnerAccount",
      });

    const fieldsDefinitions = {
      fantasy_name: "pj_fantasia",
      social_name: "pj_razaosocial",
      document_pj: "pj_cnpj",
    };

    const structureForm = getStructure(Object.keys(fieldsDefinitions)),
      schema = build(getMappedFields(Object.keys(fieldsDefinitions)));

    let initialValues = buildInitialValues(
      fieldsDefinitions,
      structureForm,
      partner.value
    );

    const { updateUserPartnerAction } = useActions({
        updateUserPartnerAction: "account/updateUserPartnerAction",
      }),
      { fetchUserAction } = useActions({
        fetchUserAction: "auth/fetchUserAction",
      });

    const onSubmit = (values) => {
      let payload = getFormValuesChanged(
        fieldsDefinitions,
        initialValues,
        values
      );

      if (Object.keys(payload).length > 0) {
        in_request.value = true;

        updateUserPartnerAction(payload)
          .then(() => {
            initialValues = values;
            notifySuccess("actions.data_changed_success");
          })
          .catch(({ errors, message }) => {
            const notify = (error) => notifyError(error, false);
            if (errors) Object.values(errors).forEach(notify);
            if (message) notify(message);
          })
          .finally(() => {
            in_request.value = false;
            fetchUserAction();
          });
      } else notifySuccess("actions.data_changed_success");
    };

    return {
      labels,
      schema,
      in_request,
      initialValues,
      structureForm,
      fieldsDefinitions,
      onSubmit,
    };
  },
};
</script>
